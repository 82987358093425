﻿import $ from 'jquery';

global.$ = $;
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

import 'ion-rangeslider';

import 'bootstrap';
import 'bootstrap-select';
import '../css/site.scss';

import './index';

console.log('The bundle has been loaded!');

$(document).ready(function () {
    document.getElementsByTagName("html")[0].style.visibility = "visible";
    $('[data-toggle="tooltip"]').tooltip();
});